<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <circle cx="50" cy="50" r="0" fill="none" stroke="#0a66a8" stroke-width="2">
      <animate attributeName="r" repeatCount="indefinite" dur="1.5384615384615383s" values="0;20" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="0s"></animate>
      <animate attributeName="opacity" repeatCount="indefinite" dur="1.5384615384615383s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="0s"></animate>
    </circle>
    <circle cx="50" cy="50" r="0" fill="none" stroke="#13a897" stroke-width="2">
      <animate attributeName="r" repeatCount="indefinite" dur="1.5384615384615383s" values="0;20" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.7692307692307692s"></animate>
      <animate attributeName="opacity" repeatCount="indefinite" dur="1.5384615384615383s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.7692307692307692s"></animate>
    </circle>
  </svg>
</template>
